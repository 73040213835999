import React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const T45Page = () => {
  return (
    <Layout>
      <section className="slider w-full mx-auto mt-16 pb-3 md:pb-10 overflow-x-hidden">
        <div className="w-full">
          <StaticImage
            src={`../images/T45.jpg`}
            alt="Type 45/69"
            aspectRatio={20 / 6}
            placeholder="blurred"
          />
        </div>
      </section>
      <section className="modelDesc">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
            <div className="flex flex-col">
              <h1 className="text-3xl md:text-6xl font-bold">
                <span className="text-xs md:text-sm pr-1">TYPE</span>
                45/69
              </h1>
              <div>
                <ul className="list-disc list-inside mt-3 text-lg">
                  <li>Fondasi Batu Kali</li>
                  <li>Sloof Beton Bertulang</li>
                  <li>Lantai Homogenous 60 x 60</li>
                  <li>Dinding Bata Ringan 20 x 60</li>
                  <li>Plester & Aci</li>
                  <li>Plafond Gypsum</li>
                  <li>Rangka Atap Baja Ringan</li>
                  <li>Atap Peredam Panas</li>
                  <li>Kusen Alumunium</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col rounded-xl overflow-hidden">
              <StaticImage
                src={`../images/T45.jpg`}
                alt="Type 45/69"
                placeholder="blurred"
                className=""
                width={800}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default T45Page;

export const Head = () => (
  <>
    <title>JEWEL Residences @ Serpong | Type 45/69</title>
    <meta
      name="description"
      content="JEWEL Residences @ Serpong Rumah Type 45, Luas Tanah 69m², Luas Bangunan 45m². Affordable housing complex in the JEWEL of Serpong"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;700;800&display=swap"
      rel="stylesheet"
    ></link>
    <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-K3DM7CP"
    ></script>
  </>
);
